import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PulseLoader from 'react-spinners/PulseLoader';
import { useLocation } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';

import Content from '../../components/layout/Content';
import { register } from '../../actions/authActions';
import { closeMenu } from '../../actions/appActions';

import { days, months } from '../../constants/dates';

import 'react-datepicker/dist/react-datepicker.css';
import './Register.css';

const years = [];
const currentYear = new Date().getFullYear();

for (let i = currentYear; i >= 1920; i--) {
    years.push(i);
}

const RegisterForm = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const authSelector = useSelector((state) => state.auth);
    const { loading, errors } = authSelector;
    const appSelector = useSelector((state) => state.app);
    const { fieldOptions, fieldOptionsByOptionID, sportsListByUniversityID } = appSelector;
    const [locationError, setLocationError] = useState(null);

    const captchaRef = useRef(null);
    const termsEl = useRef(null);
    const [termsError, setTermsError] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [repeatEmail, setRepeatEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [repeatPhone, setRepeatPhone] = useState('');
    const [day, setDay] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    const [areYouAStudent, setAreYouAStudent] = useState('');
    const [collegeUniversity, setCollegeUniversity] = useState('');
    const [yearOfGraduation, setYearOfGraduation] = useState('');
    const [societies, setSocieties] = useState([]);
    const [societySelect, setSocietySelect] = useState('');

    const [isAStudent, setIsAStudent] = useState(false);
    const [isUniversity, setIsUniversity] = useState(false);

    const [dateOfBirthError, setDateOfBirthError] = useState(null);

    const urlParams = new URLSearchParams(location.search);
    const locationParam = urlParams.get('location');

    const onSubmit = async (e) => {
        e.preventDefault();

        if (termsEl.current.checked) {
            setTermsError(null);
            setDateOfBirthError(null);
            if (year && month && day) {
                const token = captchaRef.current.getValue();

                dispatch(
					register(
						firstName,
						lastName,
						email,
						repeatEmail,
						phone,
						repeatPhone,
						year + '-' + month + '-' + day,
						password,
						repeatPassword,
						areYouAStudent,
						collegeUniversity,
						yearOfGraduation,
						societies,
						locationParam,
						token
					)
                );
            } else {
                setDateOfBirthError('Please enter your date of birth');
            }
        } else {
            setTermsError('Please check the terms to continue.');
        }
    };

    const handleAreYouAStudentChange = (e) => {
        const { value } = e.target;

        setAreYouAStudent(value);

        if (value === '24') {
            setIsAStudent(true);
        } else {
            setIsAStudent(false);
        }
    };

    const handleCollegeUniversityChange = (e) => {
        const { value } = e.target;

        setCollegeUniversity(value);
        setSocieties([]);

        if (
            value === '26' ||
            value === '27' ||
            value === '28' ||
            value === '29' ||
            value === '64' ||
            value === '63'
        ) {
            setIsUniversity(true);
        } else {
            setIsUniversity(false);
        }
    };

    const handleYearOfGraduationChange = (e) => {
        const { value } = e.target;

        setYearOfGraduation(value);
    };

    const handleSocietiesChange = (e) => {
        const { value } = e.target;

        setSocieties((prev) => [...prev, value]);
    };

    const handleRemoveSociety = (id) => {
        if (id !== '') {
            setSocieties(
                (prev) => [...prev.filter((society) => society !== id)],
                () => {
                    setSocietySelect('');
                }
            );
        }
    };

    useEffect(() => {
        dispatch(closeMenu());
    }, [dispatch]);

    useEffect(() => {
        if (errors) {
            captchaRef.current.reset();
        }
    }, [errors]);

    return (
        <Content>
            <h1>Register</h1>
            <span className="login__welcome">Please fill out all fields</span>
            <form className="login__form" onSubmit={onSubmit}>
                <div className="form-group">
                    <label className="form__label" htmlFor="firstName">
                        First Name
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={firstName}
                        autoComplete="off"
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <div className="error">{errors?.firstname?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="lastName">
                        Last Name
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={lastName}
                        autoComplete="off"
                        onChange={(e) => setLastName(e.target.value)}
                    />
                    <div className="error">{errors?.lastname?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="email">
                        Email
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        autoComplete="off"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <div className="error">{errors?.email?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="repeatEmail">
                        Retype Email
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="repeatEmail"
                        name="repeatEmail"
                        value={repeatEmail}
                        autoComplete="off"
                        onChange={(e) => setRepeatEmail(e.target.value)}
                    />
                    <div className="error">{errors?.repeat_email?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="phone">
                        Mobile Phone
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="phone"
                        name="phone"
                        value={phone}
                        autoComplete="off"
                        onChange={(e) => setPhone(e.target.value)}
                    />
                    <div className="error">{errors?.phone?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="repeatPhone">
                        Retype Mobile Phone
                    </label>
                    <input
                        className="form__input"
                        type="text"
                        id="repeatPhone"
                        name="repeatPhone"
                        value={repeatPhone}
                        autoComplete="off"
                        onChange={(e) => setRepeatPhone(e.target.value)}
                    />
                    <div className="error">{errors?.repeat_phone?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="day">
                        Date of Birth
                    </label>
                    <div className="form__selectRow">
                        <select
                            className="form__select"
                            name="day"
                            value={day.toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                            })}
                            onChange={(e) => setDay(e.target.value)}
                        >
                            <option value="">Select Day</option>
                            {days.map((day) => (
                                <option value={day.value}>{day.label}</option>
                            ))}
                        </select>
                        <select
                            className="form__select"
                            name="month"
                            value={month.toLocaleString('en-US', {
                                minimumIntegerDigits: 2,
                                useGrouping: false,
                            })}
                            onChange={(e) => setMonth(e.target.value)}
                        >
                            <option value="">Select Month</option>
                            {months.map((month) => (
                                <option value={month.value}>
                                    {month.label}
                                </option>
                            ))}
                        </select>
                        <select
                            className="form__select"
                            name="year"
                            value={year}
                            onChange={(e) => setYear(e.target.value)}
                        >
                            <option value="">Select Year</option>
                            {years.map((year) => (
                                <option value={year}>{year}</option>
                            ))}
                        </select>
                    </div>
                    <div className="error">{errors?.dob?.message}</div>
                    <div className="error">{dateOfBirthError}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="day">
                        Are you a student?
                    </label>
                    <select
                        className="form__select"
                        style={{ width: '100%' }}
                        name="areYouAStudent"
                        value={areYouAStudent}
                        onChange={handleAreYouAStudentChange}
                    >
                        <option value="">-- Please select an option --</option>
                        {fieldOptions?.are_you_a_student?.map((field) => (
                            <option value={field?.field_option_id}>
                                {field?.option_name}
                            </option>
                        ))}
                    </select>
                    <div className="error">
                        {errors?.are_you_a_student?.message}
                    </div>
                </div>
                {isAStudent && (
                    <React.Fragment>
                        <div className="form-group">
                            <label className="form__label" htmlFor="day">
                                College or University
                            </label>
                            <select
                                className="form__select"
                                style={{ width: '100%' }}
                                name="collegeUniversity"
                                value={collegeUniversity}
                                onChange={handleCollegeUniversityChange}
                            >
                                <option value="">
                                    -- Please select an option --
                                </option>
                                {fieldOptions?.college_or_university?.map(
                                    (field) => (
                                        <option value={field?.field_option_id}>
                                            {field?.option_name}
                                        </option>
                                    )
                                )}
                            </select>
                            <div className="error">
                                {errors?.college_or_university?.message}
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="form__label" htmlFor="day">
                                Year of Graduation
                            </label>
                            <select
                                className="form__select"
                                style={{ width: '100%' }}
                                name="yearOfGraduation"
                                value={yearOfGraduation}
                                onChange={handleYearOfGraduationChange}
                            >
                                <option value="">
                                    -- Please select an option --
                                </option>
                                {fieldOptions?.year_of_graduation?.map(
                                    (field) => (
                                        <option value={field?.field_option_id}>
                                            {field?.option_name}
                                        </option>
                                    )
                                )}
                            </select>
                        </div>
                        {isUniversity && fieldOptions?.[sportsListByUniversityID[collegeUniversity]] && (
                            <React.Fragment>
                                <div className="form-group">
                                    <label
                                        className="form__label"
                                        htmlFor="day"
                                    >
                                        Sport team or Society(s)
                                    </label>
                                    <select
                                        className="form__select"
                                        style={{ width: '100%' }}
                                        name="societies"
                                        value={societySelect}
                                        onChange={handleSocietiesChange}
                                    >
                                        <option value="">
                                            -- Please select an option --
                                        </option>
                                        {fieldOptions?.[sportsListByUniversityID[collegeUniversity]]?.map(
                                            (field) => (
                                                <option
                                                    value={
                                                        field?.field_option_id
                                                    }
                                                >
                                                    {field?.option_name}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                                {societies.length > 0 && (
                                    <div class="form__societies">
                                        {societies.map((society) => (
                                            <div className="society">
                                                <span>
                                                    {
                                                        fieldOptionsByOptionID[
                                                            society
                                                        ]
                                                    }
                                                </span>
                                                <span
                                                    className="close"
                                                    onClick={() =>
                                                        handleRemoveSociety(
                                                            society
                                                        )
                                                    }
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        className="h-6 w-6"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                        stroke="currentColor"
                                                    >
                                                        <path
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            strokeWidth={2}
                                                            d="M6 18L18 6M6 6l12 12"
                                                        />
                                                    </svg>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
                <div className="form-group">
                    <label className="form__label" htmlFor="password">
                        Password
                    </label>
                    <input
                        className="form__input"
                        type="password"
                        id="password"
                        name="password"
                        value={password}
                        autoComplete="off"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className="error">{errors?.password?.message}</div>
                </div>
                <div className="form-group">
                    <label className="form__label" htmlFor="repeatPassword">
                        Retype Password
                    </label>
                    <input
                        className="form__input"
                        type="password"
                        id="repeatPassword"
                        name="repeatPassword"
                        value={repeatPassword}
                        autoComplete="off"
                        onChange={(e) => setRepeatPassword(e.target.value)}
                    />
                    <div className="error">
                        {errors?.repeat_password?.message}
                    </div>
                </div>
                <div className="form-group--checkbox">
                    <label htmlFor="terms" className="form__label">
                        I understand that my contact details will be retained
                        for future check in ease of use and marketing purposes.
                        You'll be able to update your communication preferences
                        from your profile
                        <input
                            className="form__checkbox"
                            type="checkbox"
                            id="terms"
                            name="terms"
                            checked={true}
                            ref={termsEl}
                        />
                    </label>
                    <div className="error">{termsError}</div>
                </div>
                <div className="formGroup">
                    <ReCAPTCHA
                        sitekey={'6LeCBIoUAAAAAA0pIkh8_uiw8NTas5dszpno15PX'}
                        ref={captchaRef}
                    />
                    <div className="error">{errors?.token?.message}</div>
                </div>
                <div className="form__buttons">
                    <button
                        aria-label="Register"
                        disabled={loading}
                        className="form__button form__button--inline"
                        type="submit"
                    >
                        Register{' '}
                        <PulseLoader
                            loading={loading}
                            color={'#ffffff'}
                            css={'margin-left: 8px'}
                            size={5}
                        />
                    </button>
                </div>
            </form>
        </Content>
    );
};

export default RegisterForm;
