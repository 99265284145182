import { combineReducers } from 'redux';

import authReducer from './authReducer';
import appReducer from './appReducer';
import profileReducer from './profileReducer';
import dealsReducer from './dealsReducer';

export default combineReducers({
    app: appReducer,
    auth: authReducer,
    profile: profileReducer,
    deals: dealsReducer
});