import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import QRCode from 'qrcode.react';
import Clock from 'react-live-clock';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';

import styles from './Dashboard.module.css';

const Dashboard = () => {
    const authSelector = useSelector((state) => state.auth);
    const { user, isStudent, isSubscribed } = authSelector;
    const qrObj = {
        secret_code: user?.secret_code,
        customer_id: user?.customer_id,
        timestamp: Date.now(),
    };
    const [qr, setQr] = useState(qrObj);

    const generateQRCode = () => {
        setQr({ ...qrObj, timestamp: Date.now() });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const intervalId = setInterval(generateQRCode, 15 * 60 * 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [qr]);

    return (
        <Fragment>
            <Header gold />
            <Menu />
            <Content style={{ marginTop: isSubscribed ? '120px' : '87px' }}>
                <p className={styles.dashboard__text}>
                    Ensure that your unique QR code is scanned by the team at
                    your favourite venue.
                </p>
                <div className={styles.dashboard__qrcode}>
                    {user?.secret_code && user?.customer_id && (
                        <QRCode
                            value={JSON.stringify(qr)}
                            renderAs="svg"
                            size={256}
                        />
                    )}
                </div>
                <div className={styles.dashboard__time}>
                    <Clock
                        format={'DD-MM-YYYY HH:mm:ss'}
                        ticking={true}
                        timezone={'Europe/London'}
                    />
                </div>
                <div>
                    {(isSubscribed || isStudent) && (
                        <Link to="/deals">
                            <div className={styles.dashboard__deals}>
                                Your Deals
                            </div>
                        </Link>
                    )}
                </div>
            </Content>
            <BottomNav />
        </Fragment>
    );
};

export default Dashboard;
