export const TOGGLE_MENU = 'TOGGLE_MENU';
export const CLOSE_MENU = 'CLOSE_MENU';
export const NAVIGATION_REQUEST = 'NAVIGATION_REQUEST';
export const NAVIGATION_SUCCESS = 'NAVIGATION_SUCCESS';
export const NAVIGATION_FAIL = 'NAVIGATION_FAIL';
export const MENU_REQUEST = 'MENU_REQUEST';
export const MENU_SUCCESS = 'MENU_SUCCESS';
export const MENU_FAIL = 'MENU_FAIL';
export const CHECK_VERSION_SUCCESS = 'CHECK_VERSION_SUCCESS';
export const FIELD_OPTIONS_REQUEST = 'FIELD_OPTIONS_REQUEST';
export const FIELD_OPTIONS_SUCCESS = 'FIELD_OPTIONS_SUCCESS';
export const FIELD_OPTIONS_FAIL = 'FIELD_OPTIONS_FAIL';
export const SPORTS_LIST_REQUEST = 'SPORTS_LIST_REQUEST';
export const SPORTS_LIST_SUCCESS = 'SPORTS_LIST_SUCCESS';
export const SPORTS_LIST_FAIL = 'SPORTS_LIST_FAIL';
