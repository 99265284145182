import React, { Fragment, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Header from '../../components/layout/Header';
import BottomNav from '../../components/layout/BottomNav';
import Menu from '../../components/layout/Menu';
import RegisterForm from './RegisterForm';
import RegisterComplete from './RegisterComplete';
import { closeMenu } from '../../actions/appActions';


import 'react-datepicker/dist/react-datepicker.css';
import './Register.css';
import '../Login/Login.css';

const Register = () => {
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const { user, registrationCompleted } = authSelector;

    useEffect(() => {
        dispatch(closeMenu())
    }, [dispatch]);

    if (!user) {
        if (registrationCompleted) {
            return (
                <Fragment>
                    <Header />
                    <Menu />
                    <RegisterComplete />
                    <BottomNav />
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <Header />
                    <Menu />
                    <RegisterForm />
                    <BottomNav />
                </Fragment>
            );
        }
    } else {
        return (
            <Redirect push to="/" />
        );
    }
}

export default Register;
