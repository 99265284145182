import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Cookie from 'js-cookie';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import { AppAPI } from '../../services/api';
import useLocalStorage from '../../hooks/useLocalStorage';
import SelectVenue from './SelectVenue';

import styles from './Deals.module.css';

const Deals = () => {
    const authSelector = useSelector((state) => state.auth);
    const { isSubscribed } = authSelector;
    const [deals, setDeals] = useState(null);
    const [loading, setLoading] = useState(false);
    const [chooseLocation, setChooseLocation] = useState(false);
    const [location, setLocation] = useLocalStorage('locationId', '');
    const [locationName, setLocationName] = useLocalStorage('locationName', '');

    async function getDeals() {
        try {
            setLoading(true);
            const { data } = await AppAPI.get('/app_handler.php', {
                headers: {
                    'WG-Method': 'DEALS',
                    'WG-Key': Cookie.get('accessToken'),
                    'WG-LocationId': location,
                },
            });

            setDeals(data.deals || 'No deals found');
            setLocation(data.location_id || '');
            setLocationName(data.location_label || '');
            if (data.location_id === '') {
                setChooseLocation(true);
            } else {
                setChooseLocation(false);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const handleNewVenue = () => {
        setChooseLocation(true);
    };

    const renderedContent = chooseLocation ? (
        <SelectVenue
            location={location}
            setLocation={setLocation}
            setLocationName={setLocationName}
        />
    ) : (
        <>
            <h2 className={styles.heading}>Your Deals for {locationName}</h2>
            <div
                className={styles.dealsContainer}
                dangerouslySetInnerHTML={{ __html: deals }}
            ></div>
            <div className='form__buttons'>
                <button className='form__button form__button--inline' onClick={handleNewVenue}>
                    Click here to select a different venue
                </button>
            </div>
        </>
    );

    useEffect(() => {
        getDeals();
    }, []);

    useEffect(() => {
        if (location === '') {
            setChooseLocation(true);
        } else {
            getDeals();
        }
    }, [location]);

    return (
        <React.Fragment>
            <Header gold />
            <Menu />
            <Content style={{ marginTop: isSubscribed ? '120px' : '87px' }}>
                {loading ? <p>Loading...</p> : <>{renderedContent}</>}
            </Content>
            <BottomNav />
        </React.Fragment>
    );
};

export default Deals;
