import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

// import { ReactComponent as Venue } from '../../assets/venue.svg';
import { ReactComponent as LoginIcon } from '../../assets/login.svg';
import { ReactComponent as UserCircleIcon } from '../../assets/user-circle.svg';

import { ReactComponent as QRCode } from '../../assets/qrcode.svg';
import { ReactComponent as User } from '../../assets/user.svg';
import { ReactComponent as Ticket } from '../../assets/ticket.svg';

import './BottomNav.css';

const BottomNav = () => {
    const authSelector = useSelector((state) => state.auth);
    const { isAuthenticated, isSubscribed } = authSelector;

    return (
        <div className="bottomNav">
            {isAuthenticated ? (
                <Fragment>
                    <NavLink to="/" exact className={`bottomNav__link${isSubscribed ? ' bottomNav__link--gold' : ''}`} activeClassName="active"><QRCode style={{ width: '25px', height: '25px' }} />QR Code</NavLink>
                    <NavLink to="/deals" exact className={`bottomNav__link${isSubscribed ? ' bottomNav__link--gold' : ''}`} activeClassName="active"><Ticket style={{ width: '25px', height: '25px' }} />Deals</NavLink>
                    <NavLink to="/profile" className={`bottomNav__link${isSubscribed ? ' bottomNav__link--gold' : ''}`} activeClassName="active" ><User style={{ width: '30px', height: '30px' }} />Profile</NavLink>
                </Fragment>
            ) : (
                <Fragment>
                    <NavLink to="/login" className="bottomNav__link" activeClassName="active"><LoginIcon style={{ width: '33px', height: '33px' }} />Login</NavLink>
                    <NavLink to="/register" className="bottomNav__link" activeClassName="active"><UserCircleIcon style={{ width: '33px', height: '33px' }} />Register</NavLink>
                </Fragment>
            )}
        </div>
    )
}

export default BottomNav;
