import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Header from '../../components/layout/Header';
import Menu from '../../components/layout/Menu';
import BottomNav from '../../components/layout/BottomNav';
import Content from '../../components/layout/Content';
import { closeMenu } from '../../actions/appActions';
import { AppAPI } from '../../services/api';

const Help = () => {
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const { isSubscribed } = authSelector;
    const [content, setContent] = useState(null);

    useEffect(() => {
        async function getContent() {
            try {
                const { data } = await AppAPI.get('/app_handler.php', {
                    headers: { 'WG-Method': 'HELP_PAGE' },
                });

                setContent(data?.content);
            } catch (error) {
                console.log(error);
            }
        }

        dispatch(closeMenu());
        getContent();
    }, [dispatch]);

    return (
        <React.Fragment>
            <Header gold />
            <Menu />
            <Content style={{ marginTop: isSubscribed ? '120px' : '87px' }}>
                <div dangerouslySetInnerHTML={{ __html: content }}></div>
            </Content>
            <BottomNav />
        </React.Fragment>
    );
};

export default Help;
