import Cookie from 'js-cookie';

import { AppAPI } from '../services/api';

import {
    GET_DEALS_LOCATIONS_REQUEST,
    GET_DEALS_LOCATIONS_SUCCESS,
    GET_DEALS_LOCATIONS_FAIL,
    SAVE_DEALS_LOCATIONS_REQUEST,
    SAVE_DEALS_LOCATIONS_SUCCESS,
    SAVE_DEALS_LOCATIONS_FAIL,
} from '../types/dealsTypes';

export const getVenues = () => async (dispatch) => {
    try {
        dispatch({ type: GET_DEALS_LOCATIONS_REQUEST });

        const { data } = await AppAPI.get('/app_handler.php', {
            headers: {
                'WG-Method': 'LOAD_VENUES',
                'WG-Key': Cookie.get('accessToken'),
            },
        });

        dispatch({ type: GET_DEALS_LOCATIONS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: GET_DEALS_LOCATIONS_FAIL });
    }
};

export const saveVenue = (locationId) => async (dispatch) => {
    try {
        dispatch({ type: SAVE_DEALS_LOCATIONS_REQUEST });

        const { data } = await AppAPI.post(
            '/app_handler.php',
            {
                locationId,
            },
            {
                headers: {
                    'WG-Method': 'SAVE_VENUE',
                    'WG-Key': Cookie.get('accessToken'),
                },
            }
        );

        dispatch({ type: SAVE_DEALS_LOCATIONS_SUCCESS, payload: data });
    } catch (error) {
        dispatch({ type: SAVE_DEALS_LOCATIONS_FAIL });
    }
};
