import React, { useEffect } from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect,
} from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import Login from './pages/Login/Login';
import Logout from './pages/Logout/Logout';
import Register from './pages/Register/Register';
import Dashboard from './pages/Dashboard/Dashboard';
import Profile from './pages/Profile/Profile';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import Help from './pages/Help/Help';
import ProtectedRoute from './components/routes/ProtectedRoute';
import Deals from './pages/Deals/Deals';
import AddToHome from './pages/AddToHome/AddToHome';

import {
    checkVersion,
    getFieldOptions,
    getSportsList,
} from './actions/appActions';
import { getVenues } from './actions/dealsActions';
import { checkMembership } from './actions/authActions';

import './App.css';

export const App = () => {
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const { user, isAuthenticated, isSubscribed } = authSelector;

    useEffect(() => {
        dispatch(getSportsList());
        dispatch(getFieldOptions());
        dispatch(getVenues());
        dispatch(checkVersion());
    }, [dispatch]);

    useEffect(() => {
        if (user) {
            dispatch(checkMembership());
        }
    }, [dispatch, user]);

    return (
        <Router>
            <div
                className={`app${
                    window.location.pathname === '/login'
                        ? ' no-transition'
                        : ''
                }${isSubscribed ? ' app--gold' : ''}`}
            >
                <Switch>
                    <ProtectedRoute
                        path="/"
                        exact
                        component={Dashboard}
                        isAuthenticated={isAuthenticated}
                    />
                    <ProtectedRoute
                        path="/profile"
                        exact
                        component={Profile}
                        isAuthenticated={isAuthenticated}
                    />
                    <ProtectedRoute
                        path="/deals"
                        exact
                        component={Deals}
                        isAuthenticated={isAuthenticated}
                    />
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Route path="/reset-password" component={ResetPassword} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/help" component={Help} />
                    <Route path="/add-to-home" component={AddToHome} />
                    <Redirect to="/" />
                </Switch>
            </div>
        </Router>
    );
};
