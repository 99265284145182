import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LogoWhite from '../../assets/membership-scotland-logo.png';
import LogoBlack from '../../assets/membership-scotland-logo-black.png';
import GoldPass from '../../assets/gold-pass.jpg';
import FreshersPass from '../../assets/freshers-pass-23.png';
import ReFreshersPass from '../../assets/re-freshers-pass-24.png';
import { ReactComponent as Menu } from '../../assets/menu.svg';

import { toggleMenu } from '../../actions/appActions';

import './Header.css';

const Header = ({ gold, children }) => {
    const dispatch = useDispatch();
    const authSelector = useSelector((state) => state.auth);
    const { user, isSubscribed, isStudent, isPlatinum } = authSelector;

    const Logo = isSubscribed && gold ? LogoBlack : LogoWhite;
    let LogoHeader = isStudent ? FreshersPass : GoldPass;
    LogoHeader = isPlatinum ? ReFreshersPass : LogoHeader;

    const HeaderBackground = isPlatinum ? '/images/silver-background-app.png' : '/images/gold-background-app.png';

    if (isSubscribed && gold) {
        return (
            <React.Fragment>
                <div
                    className="header header--gold"
                    style={{
                        backgroundImage: `url(${
                            process.env.PUBLIC_URL +
                            HeaderBackground
                        })`,
                    }}
                >
                    <div className="header__top">
                        <img
                            className="header__logo"
                            src={Logo}
                            alt="Membership.Scot"
                        />
                        <div>
                            <button
                                aria-label="Menu"
                                onClick={() => dispatch(toggleMenu())}
                                className="header__menuIcon header__menuIcon--black"
                            >
                                <Menu />
                            </button>
                        </div>
                    </div>
                    <div>
                        <img
                            className="header__goldPass"
                            src={LogoHeader}
                            alt="Gold Pass"
                        />
                    </div>
                </div>
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            <div className="header" style={{ height: '87px' }}>
                <div className="header__top">
                    <img
                        className="header__logo"
                        src={Logo}
                        alt="Membership.Scot"
                    />
                    <div>
                        <button
                            aria-label="Menu"
                            onClick={() => dispatch(toggleMenu())}
                            className="header__menuIcon"
                        >
                            <Menu />
                        </button>
                    </div>
                </div>
                {user?.displayName && (
                    <div className="header__topMessage">
                        {window.location.pathname === '/'
                            ? `Welcome ${user?.displayName}`
                            : children}
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default Header;
