import {
    GET_DEALS_LOCATIONS_REQUEST,
    GET_DEALS_LOCATIONS_SUCCESS,
    GET_DEALS_LOCATIONS_FAIL,
} from '../types/dealsTypes';

const initialState = {
    loading: false,
    errors: null,
    venues: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_DEALS_LOCATIONS_REQUEST:
            return {
                ...state,
                loading: true,
                errors: null,
            };
        case GET_DEALS_LOCATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                errors: null,
                venues: action.payload.venues,
            };
      case GET_DEALS_LOCATIONS_FAIL:
            return {
                ...state,
                loading: false,
            };
        default:
            return state;
    }
};
