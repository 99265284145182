import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { version } from '../../constants/dates';

const Content = (props) => {
    const authSelector = useSelector(state => state.auth);
    const appSelector = useSelector((state) => state.app);

    const { isMissingFields, headerMessage } = authSelector;
    const { version: appVersion } = appSelector;

    const doesRequireUpdate = version !== appVersion;
    // const doesRequireUpdate = true;

    return (
        <div className="page" style={{ ...props.style }}>   
            {doesRequireUpdate && (
                <Link to="/help">
                    <div
                        className="header__upgrade"
                    >
                        {doesRequireUpdate && (
                            <span>Update available ({appVersion})</span>
                        )}
                    </div>
                </Link>
            )}
            {!doesRequireUpdate && isMissingFields && (
                <Link to="/profile">
                    <div
                        className="header__upgrade"
                    >
                        {isMissingFields && (
                            <span>
                                You are missing fields on your profile
                            </span>
                        )}
                    </div>
                </Link>
            )}         
            {headerMessage ? (
                <div className='header__message'>{headerMessage}</div>
            ) : null}
            <div className="page-content">
                {props.children}
            </div>
        </div>
    )
}

export default Content;