import {
    TOGGLE_MENU,
    CLOSE_MENU,
    NAVIGATION_SUCCESS,
    MENU_REQUEST,
    MENU_SUCCESS,
    MENU_FAIL,
    CHECK_VERSION_SUCCESS,
    FIELD_OPTIONS_SUCCESS,
    SPORTS_LIST_REQUEST,
    SPORTS_LIST_SUCCESS,
} from '../types/appTypes';

const appVersion = localStorage.getItem('appVersion');

const initialState = {
    navigation: [],
    menu: null,
    menuOpen: false,
    fieldOptions: null,
    fieldOptionsByOptionID: null,
    sportsListByUniversityID: null,
    version: appVersion || null,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NAVIGATION_SUCCESS:
            return {
                ...state,
                navigation: action.payload,
            };
        case MENU_REQUEST:
            return {
                ...state,
                menu: null,
            };
        case MENU_SUCCESS:
            return {
                ...state,
                menu: action.payload,
            };
        case MENU_FAIL:
            return {
                ...state,
            };
        case TOGGLE_MENU:
            return {
                ...state,
                menuOpen: !state.menuOpen,
            };
        case CLOSE_MENU:
            return {
                ...state,
                menuOpen: false,
            };
        case CHECK_VERSION_SUCCESS:
            return { ...state, version: action.payload.app_version };
        case FIELD_OPTIONS_SUCCESS:
            return {
                ...state,
                fieldOptions: action.payload.options,
                fieldOptionsByOptionID:
                    action.payload.field_options_by_option_id,
            };
        case SPORTS_LIST_REQUEST:
            return {
                ...state,
            };
        case SPORTS_LIST_SUCCESS:
            return {
                ...state,
                sportsListByUniversityID: action.payload,
            };
        default:
            return state;
    }
};
