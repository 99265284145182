import Cookie from 'js-cookie';

import { AppAPI } from '../services/api';

import {
    TOGGLE_MENU,
    CLOSE_MENU,
    NAVIGATION_REQUEST,
    NAVIGATION_FAIL,
    NAVIGATION_SUCCESS,
    CHECK_VERSION_SUCCESS,
    FIELD_OPTIONS_REQUEST,
    FIELD_OPTIONS_SUCCESS,
    FIELD_OPTIONS_FAIL,
    SPORTS_LIST_REQUEST,
    SPORTS_LIST_SUCCESS,
    SPORTS_LIST_FAIL,
} from '../types/appTypes';

export const toggleMenu = () => (dispatch) => {
    dispatch({ type: TOGGLE_MENU });
};

export const closeMenu = () => (dispatch) => {
    dispatch({ type: CLOSE_MENU });
};

export const setNavigation = () => async (dispatch) => {
    try {
        dispatch({ type: NAVIGATION_REQUEST });

        const navigation = await AppAPI.get('/app_handler.php', {
            headers: {},
        });

        if (navigation) {
            dispatch({ type: NAVIGATION_SUCCESS, payload: navigation });
        }
    } catch (error) {
        dispatch({ type: NAVIGATION_FAIL });
    }
};

export const checkVersion = () => async (dispatch) => {
    try {
        const { data } = await AppAPI.get('/app_handler.php', {
            headers: { 'Wg-Method': 'CHECK_VERSION' },
        });

        if (data) {
            dispatch({ type: CHECK_VERSION_SUCCESS, payload: data });
        }

        Cookie.set('checked_app_version', 'true', { expires: 1 });
        localStorage.setItem('appVersion', data?.app_version);
    } catch (error) {}
};

export const getFieldOptions = () => async (dispatch) => {
    try {
        dispatch({ type: FIELD_OPTIONS_REQUEST });

        const { data } = await AppAPI.get('/app_handler.php', {
            headers: { 'Wg-Method': 'FIELD_OPTIONS' },
        });

        if (data) {
            dispatch({ type: FIELD_OPTIONS_SUCCESS, payload: data });
        }
    } catch (error) {
        dispatch({ type: FIELD_OPTIONS_FAIL });
    }
};

export const getSportsList = () => async (dispatch) => {
    try {
        dispatch({ type: SPORTS_LIST_REQUEST });

        const { data } = await AppAPI.get('/app_handler.php', {
            headers: { 'Wg-Method': 'SPORTS_LIST_BY_OPTION_ID' },
        });

        if (data) {
            dispatch({ type: SPORTS_LIST_SUCCESS, payload: data });
        }
    } catch (error) {
        dispatch({ type: SPORTS_LIST_FAIL });
    }
};
