import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveVenue } from '../../actions/dealsActions';

import styles from './Deals.module.css';

const SelectVenue = ({ location, setLocation, setLocationName }) => {
    const dispatch = useDispatch();
    const { venues, loading } = useSelector((state) => state.deals);

    const handleVenueSelect = (e) => {
        setLocation(Number(e.target.value));
        setLocationName(e.target.selectedOptions[0].text);
        dispatch(saveVenue(Number(e.target.value)));
    };

    return (
        <div>
            <h2 className={styles.heading}>Select your venue to proceed</h2>
            {venues.length > 0 ? (
                <div className={styles.selectContainer}>
                    <select className="form__select" onChange={handleVenueSelect} value={location}>
                        <option value="">-- Please select a venue --</option>
                        {venues.map((venue) => (
                            <option
                                key={venue.location_id}
                                value={venue.location_id}
                            >
                                {venue.location_company}
                            </option>
                        ))}
                    </select>
                </div>
            ) : null}
        </div>
    );
};

export default SelectVenue;
